/**
 * Functional utilities
 */

/**
 * Evaluates each of the argument predicates and returns the value
 *
 */
type ConditionPair<T> = [boolean, T];

export function cond<T>(...args: Array<ConditionPair<T>>): T | undefined {
  for (let [pred, expr] of args) {
    if (pred) return expr;
  }
}

/**
 * Returns an array of integers
 * @param start (optional) the starting number, inclusive
 * @param stop the stop number, not inclusive
 * @param step (optional) the increment / decrement step
 */
export function range(arg1: number, arg2?: number, arg3?: number): number[] {
  let stop = arguments.length > 1 ? arg2! : arg1;
  let start = arguments.length > 1 ? arg1 : 0;
  let step = arguments.length > 2 ? arg3! : 1;

  let result: number[] = [];

  // detect invalid arguments
  if (step === 0) throw new Error(`step argument must not be zero`);

  // define a predicate to detect the end of range
  let endP: (i: number, end: number) => boolean =
    step > 0 ? (i, end) => i < end : (i, end) => i > end;

  for (let i = start; endP(i, stop); i += step) {
    result.push(i);
  }

  return result;
}

/**
 * Groups elements by a specified key or predicate
 *
 */
export type GroupingPredicate<T> = (el: T) => string;

export interface GroupedItems<T> {
  [k: string]: T[];
}

export function groupBy<T, K extends keyof T>(
  elements: T[],
  criteria: GroupingPredicate<T> | K
): GroupedItems<T> {
  let getKey: GroupingPredicate<T> =
    typeof criteria === 'function' ? criteria : e => (e as any)[criteria] as string;

  let result: GroupedItems<T> = {};
  elements.forEach(el => {
    let key = getKey(el);
    if (!result[key]) {
      result[key] = [];
    }
    result[key].push(el);
  });

  return result;
}

/**
 * Same as `groupBy`, but the grouping criteria may contain multiple keys instead of just one
 */
export type MultiGroupingPredicate<T> = (el: T) => string[];

export function groupByMulti<T, K extends keyof T>(
  elements: T[],
  criteria: MultiGroupingPredicate<T> | K
): GroupedItems<T> {
  let getKeys: MultiGroupingPredicate<T> =
    typeof criteria === 'function' ? criteria : e => (e as any)[criteria] as string[];

  let result: GroupedItems<T> = {};
  elements.forEach(el => {
    let keys = getKeys(el);
    keys.forEach(key => {
      if (!result[key]) {
        result[key] = [];
      }
      result[key].push(el);
    });
  });

  return result;
}

/**
 * Create a mapping of elements by a specified key or predicate
 *
 */
export type MappingPredicate<T> = GroupingPredicate<T>;

export interface MappedItems<T> {
  [k: string]: T;
}

export function mapBy<T, K extends keyof T>(
  elements: T[],
  criteria: MappingPredicate<T> | K
): MappedItems<T> {
  let getKey: MappingPredicate<T> =
    typeof criteria === 'function' ? criteria : e => (e as any)[criteria] as string;

  let result: MappedItems<T> = {};
  elements.forEach(el => {
    let key = getKey(el);
    result[key] = el;
  });

  return result;
}

/**
 * Return true if `predicate` is true for all `elements`
 *
 */
export function all<T>(elements: T[], predicate: (el: T) => boolean): boolean {
  for (let element of elements) {
    if (!predicate(element)) return false;
  }

  return true;
}

/**
 * Return true if `predicate` is true for any of `elements`
 *
 */
export function some<T>(elements: T[], predicate: (el: T) => boolean): boolean {
  for (let element of elements) {
    if (predicate(element)) return true;
  }

  return false;
}

/**
 * Return a new array alternating the contents of array1 and array1
 *
 */
export function zip<T>(array1: T[], array2: T[]): T[] {
  let result: T[] = [];
  let i;
  for (i = 0; i < array1.length && i < array2.length; i++) {
    result.push(array1[i]);
    result.push(array2[i]);
  }

  let remaining = array1.length > array2.length ? array1 : array2;
  for (let j = i; j < remaining.length; j++) {
    result.push(remaining[j]);
  }
  return result;
}

/**
 * Returns whether an element of type T | undefined is not undefined. Can be used to filter out
 * undefineds in an array (array.filter(notUndefined))
 */
export function notUndefined<T>(x: T | undefined): x is T {
  return x !== undefined;
}

type Falsy = null | undefined | false | '' | 0;

export function notFalsy<T>(x: T | Falsy): x is T {
  return !!x;
}

/**
 * Given an id and a list of mappings that contain an `_id` field, return the
 * element in the mappings list so that the element's _id is equal to the
 * searched id.
 *
 */
export function searchById<T extends { _id: string }>(
  elementId: string,
  idMappings: T[]
): T | undefined {
  /**
   * We use toString() in case we are receiving an ObjectId in the server,
   * so we can compare them
   */
  return idMappings.find(candidate => candidate._id.toString() === elementId.toString());
}

export function partition<T>(array: T[], filter: (elem: T, indx: number, array: T[]) => unknown) {
  const pass: T[] = [];
  const fail: T[] = [];

  array.forEach((elem, idx, arr) => {
    const result = filter(elem, idx, arr);
    (result ? pass : fail).push(elem);
  });

  return [pass, fail];
}

export function intersection<T>(
  array1: T[],
  array2: T[],
  key?: keyof T | ((k1: T, k2: T) => boolean)
) {
  const shared: T[] = [];

  for (const e1 of array1) {
    for (const e2 of array2) {
      if (typeof key === 'function' && key(e1, e2)) {
        shared.push(e1);
      } else if (typeof key !== 'function' && key ? e1[key] === e2[key] : e1 === e2) {
        shared.push(e1);
      }
    }
  }

  return shared;
}

export function subtraction<T>(
  array1: T[],
  array2: T[],
  key?: keyof T | ((k1: T, k2: T) => boolean)
) {
  const inArr1NotInArr2: T[] = [];

  for (const e1 of array1) {
    let found = false;
    for (const e2 of array2) {
      if (key) {
        if (typeof key === 'function' && key(e1, e2)) {
          found = true;
        } else if (typeof key !== 'function' && e1[key] === e2[key]) {
          found = true;
        }
      } else if (e1 === e2) {
        found = true;
      }
    }
    if (!found) {
      inArr1NotInArr2.push(e1);
    }
  }

  return inArr1NotInArr2;
}

// can be used to remove duplicates in simple arrays like so: arr.filter(onlyUnique)
export function onlyUnique<T>(value: T, index: number, self: T[]) {
  return self.indexOf(value) === index;
}

// returns a filtered list removing duplicates by keeping only the first instance
// of some criteria
export function firstOf<T>(arr: T[], criteriaFn: (el: T) => string) {
  const set = new Set<string>();

  return arr.filter(a => {
    const decider = criteriaFn(a);
    if (set.has(decider)) return false;
    else {
      set.add(decider);
      return true;
    }
  });
}

export function replaceMulti(str: string, ...replacements: string[][]) {
  let result = str;

  replacements.forEach(([from, to]) => {
    result = result.replace(from, to);
  });

  return result;
}

export function firstEl<T>(list: T[]) {
  return list[0];
}

export function lastEl<T>(list: T[]) {
  return list[list.length - 1];
}

export function firstN<T>(list: T[], amt: number) {
  const subset: T[] = [];
  const safeAmt = Math.min(amt, list.length);

  for (let i = 0; i < safeAmt; i++) {
    subset.push(list[i]);
  }
  return subset;
}

export function move<T>(list: T[], fromIndex: number, toIndex: number) {
  if (list.length === 0) return [];
  const element = list[fromIndex];
  list.splice(fromIndex, 1);
  list.splice(toIndex, 0, element);
}

export function swap<T extends {}>(obj1: T, obj2: T, key: keyof T) {
  const temp = obj1[key];
  obj1[key] = obj2[key];
  obj2[key] = temp;
}
