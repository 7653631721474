import { Time } from '../../shared/types/time';
import { HourFormat } from '../types/brand';

export const DAY_LENGTH = 24 * 60;
export const DAY_LENGTH_MILLIS = DAY_LENGTH * 60 * 1000;
const MS_PER_MINUTE = 60 * 1000;
const MS_PER_HOUR = MS_PER_MINUTE * 60;
const MS_PER_DAY = MS_PER_HOUR * 24;
const MS_PER_MONTH = MS_PER_DAY * 30;
const MS_PER_YEAR = MS_PER_MONTH * 12;

export type TimeStringFormat = 'compact' | 'locale' | 'xcompact';

export function numpad(n: number, length: number = 2, fillChar: string = '0'): string {
  let s = String(n);
  return s.length >= length ? s : fillChar.repeat(length - s.length) + s;
}

export function timeToMinutes(time: Time, timeOffset?: number): number {
  timeOffset = timeOffset || 0;
  let minutes = normalizeMinutes(time.hour * 60 + time.minute - timeOffset);
  return minutes % DAY_LENGTH;
}

/**
 * Compares two times and returns:
 *  - 0 if they are the same
 *  - < 0 if time1 is less than time2
 *  - > 0 if time1 is greater than time2
 *
 * It optionally takes a time offset, so for example if the offset is 6 hours, then 5:00 is greater
 * than 6:00
 */
export function timeCompare(time1: Time, time2: Time, timeOffset?: number): number {
  let timeMinutes1 = timeToMinutes(time1, timeOffset);
  let timeMinutes2 = timeToMinutes(time2, timeOffset);

  return timeMinutes1 - timeMinutes2;
}

// WARNING: The format and hour24 parameters have been switched from the SET version!!!
export function timeToString(
  time: Time,
  hour24: boolean = false,
  format?: TimeStringFormat
): string {
  // ensure the time is not over 24 h, 0 mins
  time.hour = time.hour % 24;
  time.minute = time.minute % 60;

  if (hour24) {
    return timeToString24(time, format);
  }

  let t = Object.assign({}, time);
  let suffix;

  let am;
  let pm;

  switch (format) {
    case 'compact':
    case 'xcompact':
      am = '';
      pm = 'p';
      break;
    case 'locale':
      am = ' AM';
      pm = ' PM';
      break;
    default:
      am = 'am';
      pm = 'pm';
      break;
  }

  if (t.hour === 0) {
    t.hour = 12;
    suffix = am;
  } else if (t.hour === 12) {
    suffix = pm;
  } else {
    suffix = t.hour < 12 ? am : pm;
    t.hour %= 12;
  }

  return timefmt(t, format) + suffix;
}

// WARNING: The negative clamping is not consistent with SET!!! (i.e.,
// `minutesToTime(-1)` -> {h: -1, minute: -1} in SET, but {h: 23, m: 59} in GO)
export function minutesToTime(minutes: number): Time {
  minutes = normalizeMinutes(minutes) % DAY_LENGTH;

  return {
    hour: Math.floor(minutes / 60),
    minute: minutes % 60,
  };
}

export function minutesToTimeString(
  minutes: number,
  hour24: boolean = false,
  format?: TimeStringFormat
): string {
  return timeToString(minutesToTime(minutes), hour24, format);
}

export function addMinutesToTime(time: Time, minutes: number): Time {
  let timeMinutes = timeToMinutes(time);
  return minutesToTime(timeMinutes + minutes);
}

export function minutesSinceDayStart(minutes: number, dayStartMinutes: number) {
  return (minutes - dayStartMinutes + DAY_LENGTH) % DAY_LENGTH;
}

/**
 * Adds a number of minutes to `startTime`. The result is an object with the new
 * time and a number of days to offset, in case the time goes to the next day.
 */
export function calculateTimeOffset(startTime: Time, minutes: number, dayStart?: number) {
  dayStart = dayStart || 0;

  let time = addMinutesToTime(startTime, minutes);
  let minutesOffset = minutesSinceDayStart(timeToMinutes(startTime), dayStart);
  let dayOffset = Math.floor((minutesOffset + minutes) / DAY_LENGTH);

  return {
    time,
    dayOffset,
  };
}

export function calculateTimeDifference(endTime: Time, startTime: Time): number {
  let endTimeMinutes = timeToMinutes(endTime);
  let startTimeMinutes = timeToMinutes(startTime);

  return normalizeMinutes(endTimeMinutes - startTimeMinutes);
}

export function dateToString(date: Date): string {
  return `${numpad(date.getMonth() + 1)}/${numpad(date.getDate())}/${date.getFullYear()}`;
}

export function dateTimeToString(date: Date): string {
  return (
    `${numpad(date.getMonth() + 1)}/${numpad(date.getDate())}/${date.getFullYear()} ` +
    `${numpad(date.getHours())}:${numpad(date.getMinutes())}:${numpad(date.getSeconds())}`
  );
}

export function dateToStringLong(date: Date): string {
  return `${MONTH_NAMES_SHORT[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
}

export function dateToStringLonger(date: Date): string {
  return `${WEEK_DAYS[date.getDay()]}, ${
    MONTH_NAMES_SHORT[date.getMonth()]
  } ${date.getDate()}, ${date.getFullYear()}`;
}

export function dateToStringLongerNoYear(date: Date): string {
  return `${WEEK_DAYS[date.getDay()]}, ${MONTH_NAMES_SHORT[date.getMonth()]} ${date.getDate()}`;
}

export function dateTimeToStringLong(date: Date): string {
  return (
    `${MONTH_NAMES_SHORT[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()} ` +
    `${numpad(date.getHours())}:${numpad(date.getMinutes())}:${numpad(date.getSeconds())}`
  );
}

// Same as dateToString, but it reads the input date as UTC
export function dateToUTCString(date: Date): string {
  return `${numpad(date.getUTCMonth() + 1)}/${numpad(date.getUTCDate())}/${date.getUTCFullYear()}`;
}

export function dateToUTCStringLong(date: Date): string {
  return `${MONTH_NAMES_SHORT[date.getUTCMonth()]} ${date.getUTCDate()}, ${date.getUTCFullYear()}`;
}

export function staffMinutesToTime(mins: number, startTime: Time, format: '12' | '24') {
  let startTimeMinutes = timeToMinutes(startTime);
  let time = normalizeMinutes(mins + startTimeMinutes) % DAY_LENGTH;
  let hourStr = Math.trunc(time / 60);
  let minStr = Math.abs(time % 60);

  let timeString =
    format === '12'
      ? timeToString({ hour: hourStr, minute: minStr })
      : `${staffTimePad(hourStr)}:${staffTimePad(minStr)}`;

  // Remove the leading zero, if any
  if (format === '12' && timeString.startsWith('0')) {
    let hour = timeString.split(':')[0];
    if (hour.length === 2) {
      timeString = timeString.slice(1);
    }
  }

  return timeString;
}

export function staffMinutesToRelativeTime(mins: number): string {
  if (Math.abs(mins) < 60) {
    return `${mins}m`;
  } else {
    // This is similar to `staffMinutesToTime`, but here we want negative times, while in the
    // above we normalize it to positive only
    let hourStr = Math.trunc(mins / 60);
    let minsInHour = Math.abs(mins % 60);
    let minStr = minsInHour === 0 ? '' : ':' + staffTimePad(minsInHour);
    return `${hourStr}${minStr}h`;
  }
}

export function relativeTimeToMinutes(str: string): number {
  // First, remove either the 'm' or the 'h' characters
  str = str.replace(/mh/i, '');
  let colonIndex = str.indexOf(':');
  let result;

  if (colonIndex === -1) {
    result = parseInt(str, 10);
  } else {
    let fields = str.split(':');
    let hours = parseInt(fields[0], 10);
    let minutes = parseInt(fields[1], 10);
    result = hours * 60 + minutes;
  }

  if (Number.isNaN(result)) {
    throw new Error(`Invalid time ${str}`);
  }

  return result;
}

export function minutesToTimeSpan(minutes: number): string {
  let hours = Math.trunc(minutes / 60);
  let hourSuffix = hours > 1 ? 'hrs' : 'hr';
  if (minutes < 60) return numpad(minutes) + 'm';
  return `${hours}:${numpad(minutes % 60)}${hourSuffix}`;
}

export function isoDate(date: Date): string {
  return date.toISOString().substring(0, 10);
}

export function today(): string {
  return isoDate(getDate());
}

export function todayLocalIso(): string {
  return isoLocalDate(getDate());
}

export function todayDate(): Date {
  return parseDate(today())!;
}

export function todayLocalDate(): Date {
  return parseDate(todayLocalIso())!;
}

/**
 * Returns an ISO string in the local timezone
 * @param date date to be converted
 */
export function isoLocalDate(date: Date): string {
  const timezoneOffset = date.getTimezoneOffset() * MS_PER_MINUTE;
  return new Date(date.getTime() - timezoneOffset).toISOString().substring(0, 10);
}

export function getUTCDate(date?: Date): Date {
  date = date || new Date();
  return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()));
}

/**
 * Converts a datetime into a date, i.e. strips the hour,minute,seconds,millis
 *
 */
export function getDate(date?: Date): Date {
  date = date || new Date();
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

export function getLocalDate(): Date {
  let date = getDate();
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
}

export function isSameDay(date1: Date, date2: Date): boolean {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}

export function isSameDayUTC(date1: Date, date2: Date): boolean {
  return (
    date1.getUTCFullYear() === date2.getUTCFullYear() &&
    date1.getUTCMonth() === date2.getUTCMonth() &&
    date1.getUTCDate() === date2.getUTCDate()
  );
}

export function isSameTime(time1?: Time, time2?: Time): boolean {
  if (!time1 || !time2) {
    // If both times are undefined, return true
    if (!time1 && !time2) return true;
    // If one of the times is undefined but not the other, return false
    return false;
  }
  return time1.hour === time2.hour && time1.minute === time2.minute;
}

export function stringToTime(str: string): Time {
  if (!str) {
    throw new Error('Time should not be empty');
  }

  let timeSuffix = str.substring(str.length - 2).toLowerCase();
  let _12hFormat = timeSuffix === 'am' || timeSuffix === 'pm';

  // remove suffix from string if 12h format so that we can parse the numbers
  if (_12hFormat) {
    str = str.substring(0, str.length - 2);
  }

  let [hourString, minuteString] = str.split(':').map(s => s.trim());

  if (!hourString || hourString.length > 2) {
    throw new Error('Invalid hours ' + str);
  }

  // Need to cast because the destructuring above does not account for
  // minuteString being undefined
  if ((minuteString as any) === undefined) {
    // Special case where the minutes are not specified
    minuteString = '00';
  } else if (minuteString.length !== 2) {
    throw new Error('Invalid minutes ' + str);
  }

  let hour = parseInt(hourString, 10);
  let minute = parseInt(minuteString, 10);

  if (Number.isNaN(hour) || Number.isNaN(minute)) {
    throw new Error('Invalid time ' + str);
  }

  // Convert to 24h format
  if (
    _12hFormat &&
    ((timeSuffix === 'pm' && hour !== 12) || (timeSuffix === 'am' && hour === 12))
  ) {
    hour = (hour + 12) % 24;
  }

  // Do some validations
  if (minute < 0 || minute > 59 || hour < 0 || hour > 24) {
    throw new Error('Invalid time ' + str);
  }

  return {
    hour,
    minute,
  };
}

export function stringToTimeIfValid(str: string): Time | undefined {
  try {
    const time = stringToTime(str);
    return time;
  } catch {
    return undefined;
  }
}

export function parseDate(str: string): Date | undefined {
  let date = new Date(str);
  if (Number.isNaN(date.getTime())) return;
  return date;
}

/**
 * Parses a date in the format YYYY-MM-DD into a Date, only if the string has the right shape
 *
 */
const VALID_DATE_REGEX = RegExp('^[0-9]{4}-[0-9]{2}-[0-9]{2}$');

export function parseDateIfValid(str: string): Date | undefined {
  str = str.trim();
  if (!VALID_DATE_REGEX.test(str)) return undefined;
  return parseDate(str);
}

export function isValidTime(u: unknown): u is Time {
  if ((u as any).hour === undefined || (u as any).minute === undefined) return false;

  let hour = (u as any).hour;
  let minute = (u as any).minute;

  if (typeof hour !== 'number' || typeof minute !== 'number') return false;

  let time = u as Time;
  if (time.hour < 0 || time.hour > 23 || time.minute < 0 || time.minute > 59) return false;

  return true;
}

/**
 * Note that we work in UTC because adding a day to a local date could result in error results if
 * there are daylight savings time changes
 *
 */
export function addDaysToUTCDate(date: Date, days: number): Date {
  let d = new Date(date);
  d.setUTCDate(date.getUTCDate() + days);
  return d;
}

export function dateToTime(date: Date): Time {
  return { hour: date.getHours(), minute: date.getMinutes() };
}

export function normalizeMinutes(minutes: number): number {
  while (minutes < 0) {
    minutes += DAY_LENGTH;
  }
  return minutes;
}

/**
 * Returns true if `date` represents a day in UTC. That means it has no timezone offset, and hour,
 * minutes and seconds are set to 0
 *
 */
export function isDateUTC(date: Date): boolean {
  return (
    date.getUTCHours() === 0 &&
    date.getUTCMinutes() === 0 &&
    date.getUTCSeconds() === 0 &&
    date.getUTCMilliseconds() === 0
  );
}

export function getDateWeekDay(date: Date): string {
  return date.toLocaleString('en', { weekday: 'short' }).toUpperCase();
}

export const getUTCDateWeekDay = (date: Date) => {
  const timezoneOffset = date.getTimezoneOffset() * 60000;
  const newDate = new Date(date.valueOf() + timezoneOffset);
  return newDate.toLocaleString('en', { weekday: 'short' }).toUpperCase();
};

function timefmt(o: Time, format?: TimeStringFormat) {
  if (format === 'compact' || format === 'locale') {
    return o.hour + ':' + numpad(o.minute);
  } else if (format === 'xcompact') {
    return o.minute === 0 ? String(o.hour) : o.hour + ':' + numpad(o.minute);
  } else {
    return numpad(o.hour) + ':' + numpad(o.minute);
  }
}

function timeToString24(time: Time, format?: TimeStringFormat) {
  return timefmt(Object.assign({}, time), format);
}

function staffTimePad(n: number) {
  let negative = n < 0;
  if (negative) {
    n = Math.abs(n);
  }
  let padded = numpad(n);
  return (negative ? '-' : '') + padded;
}

export function timeToAgoString(dateSince: Date, currentDate: Date) {
  const ms = Math.floor(currentDate.getTime() - dateSince.getTime());

  const checkPlurality = (time: number) => (time > 1 ? 's' : '');
  if (ms < 0) return '';
  else if (ms < MS_PER_MINUTE) {
    const time = Math.round(ms / 1000);
    return `${time} second${checkPlurality(time)} ago`;
  } else if (ms < MS_PER_HOUR) {
    const time = Math.round(ms / MS_PER_MINUTE);
    return `${time} minute${checkPlurality(time)} ago`;
  } else if (ms < MS_PER_DAY) {
    const time = Math.round(ms / MS_PER_HOUR);
    return `${time} hour${checkPlurality(time)} ago`;
  } else if (ms < MS_PER_MONTH) {
    const time = Math.round(ms / MS_PER_DAY);
    return `${time} day${checkPlurality(time)} ago`;
  } else if (ms < MS_PER_YEAR) {
    const time = Math.round(ms / MS_PER_MONTH);
    return `${time} month${checkPlurality(time)} ago`;
  } else return 'long ago';
}

export function lastDayInMonth(month: number, year: number) {
  return new Date(year, month + 1, 0).getDate();
}

/**
 * Converts a date to a locale date string in UTC
 *
 * @todo implement test
 * @param date Date to be converted
 * @param locale Date locales
 * @param options DateTimeFormatOptions
 */
export const getLocaleUTCDateString = (
  date: Date,
  locale?: string | string[],
  options?: Intl.DateTimeFormatOptions
) => {
  const timezoneOffset = date.getTimezoneOffset() * 60000;
  const newDate = new Date(date.valueOf() + timezoneOffset);
  return newDate.toLocaleDateString(locale, options);
};

export const getLocaleUTCDateTimeString = (
  date: Date,
  locale?: string | string[],
  options?: Intl.DateTimeFormatOptions
) => {
  const timezoneOffset = date.getTimezoneOffset() * 60000;
  const newDate = new Date(date.valueOf() + timezoneOffset);
  return newDate.toLocaleString(locale, options);
};

export const WEEK_DAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const MONTH_NAMES_SHORT = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export function findMinMaxDate(dates: Date[]) {
  let minDate: Date | undefined;
  let maxDate: Date | undefined;

  for (const date of dates) {
    if (!minDate || minDate > date) minDate = date;
    if (!maxDate || maxDate < date) maxDate = date;
  }

  return [minDate, maxDate];
}

export function isIsoDate(iso: string) {
  return /\d{4}-\d{2}-\d{2}/.test(iso);
}

/**
 * Convers YYYY-MM-DD to MM/DD/YYYY
 */
export function isoToMonthDayYear(iso: string) {
  if (!isIsoDate(iso)) return undefined;
  const tokens = iso.split('-');
  if (tokens.length !== 3) return undefined;
  return `${tokens[1]}/${tokens[2]}/${tokens[0]}`;
}

// uses round due to daylight savings shenanigans
export function daysBetweenIsoDates(iso1: string, iso2: string) {
  return Math.round((Date.parse(iso1) - Date.parse(iso2)) / 86400000);
}

export function isHour24(hourFormat: HourFormat): boolean {
  return hourFormat === '24';
}

/*
'use strict';

const DAY_LENGTH = 24 * 60;






  minutesToDate: function(mins, dayOffset) {
    let d = new Date(0, 0, 0, 0, 0, 0);
    d.setMinutes(dayOffset + mins);
    return d;
  },




};

*/
