import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom';
// @ts-ignore-next-line no types
import retargetEvents from 'react-shadow-dom-retarget-events';
import urljoin from 'url-join';
import { EmbedConfig } from '../..';
import icomoonWoff from '../../../../../../client/assets/go-embed-icomoon.woff';
import icomoonTtf from '../../../../../../client/assets/go-embed-icomoon.ttf';

import App from './App';

let tries = 0;

export default async function render(additionalFonts?: (config: EmbedConfig) => string) {
  const shadowParent = document.getElementById('go-shadow');

  if (!shadowParent || !shadowParent.dataset.url) {
    if (tries < 3) {
      tries += 1;
      setTimeout(() => render(), 1000);
    } else {
      console.error(`Missing shadow element or url`);
    }
    return;
  }

  try {
    const shadowRoot = shadowParent.attachShadow({ mode: 'open' });

    const response = await axios.get<EmbedConfig>(shadowParent.dataset.url);
    const config = response.data;

    const icomoonWoffPath = urljoin(config.goBaseUrl, icomoonWoff);
    const icomoonTtfPath = urljoin(config.goBaseUrl, icomoonTtf);

    const fonts = document.createElement('style');
    fonts.innerHTML = `
      @font-face {
        font-family: 'go-embed-icomoon';
        src: url('${icomoonWoffPath}') format('woff'),
          url('${icomoonTtfPath}') format('truetype');
        font-weight: normal;
        font-style: normal;
        font-display: block;
      }
      ${additionalFonts ? additionalFonts(config) : ''}`;

    document.head.appendChild(fonts);

    const hidden = document.createElement('style');
    hidden.innerHTML = '#go-app { display: none; }';
    shadowRoot.appendChild(hidden);

    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = urljoin(config.goBaseUrl, `styles-embed-${config.clientName}.css`);
    link.crossOrigin = '';
    shadowRoot.appendChild(link);

    const appDiv = document.createElement('div');
    shadowRoot.appendChild(appDiv);

    ReactDOM.render(<App config={config} />, appDiv);
    // workaround required because events in react dont work by default on shadow dom
    retargetEvents(appDiv);
  } catch (err) {
    console.error(err);
  }
}
