import { ObjectId } from 'bson';
import uuid from 'uuid';
import { notUndefined } from './functional';

/**
 * Returns true if the argument is not `undefined`
 *
 */
export function isDefined(x: any): boolean {
  return x !== undefined;
}

/**
 * Returns true if the argument is truthy
 */
type Falsy = false | 0 | '' | null | undefined;
export function isTruthy<T>(x: T | Falsy): x is T {
  return !!x;
}

/**
 * Polyfill for `Object.values`: returns all values for an object
 *
 */
export function objectValues<T>(obj: { [k: string]: T }): T[] {
  return Object.keys(obj).reduce((v: T[], k: string) => v.concat([obj[k]]), []);
}

/**
 * Given an array of elements with type T, return one of those elements at random
 *
 */
export function choice<T>(array: T[]): T {
  return array[Math.floor(Math.random() * array.length)];
}

/**
 * Returns true when passing {}, or false otherwise
 *
 */
export function isEmptyObject(obj: any): boolean {
  try {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  } catch (_e) {
    return false;
  }
}

/**
 * Given a request field that's supposed to be true/false, return an actual
 * boolean.
 *
 */
export function parseBoolean(value?: string): boolean {
  if (!value) return false;
  return (
    `${value}`
      .toLowerCase()
      .trim()
      .match(/^(t|true|1)$/) !== null
  );
}

/**
 * Similar to Bluebird's Promise.reflect. Wraps a promise and always resolves it, returning instead
 * an object with information about what happened with the promise
 *
 */
export type PromiseInspection<T> = Promise<PromiseInspectionResult<T>>;

export interface PromiseInspectionResult<T> {
  success: boolean;
  error?: any;
  value?: T;
}

export async function reflectPromise<T>(promise: Promise<T>): PromiseInspection<T> {
  try {
    let value = await promise;
    return {
      success: true,
      value,
    };
  } catch (error) {
    return {
      success: false,
      error,
    };
  }
}

export const copyToClipboard = (value: string) => {
  const el = document.createElement('textarea');
  el.value = value;
  document.body.appendChild(el);
  el.select();
  // FIXME: We need to switch to the Clipboard API, but we should first check compatibility is good
  // tslint:disable-next-line
  document.execCommand('copy');
  document.body.removeChild(el);
};

export const styleToString = (style: { [key: string]: string | number }) => {
  let stylesString = '';

  style &&
    Object.keys(style).forEach(key => {
      stylesString += key.replace(/[A-Z]/g, match => `-${match.toLowerCase()}`);
      stylesString += ':';
      stylesString += style[key];
      stylesString += ';';
    });
  return stylesString;
};

// Used to create a string union type from an array of strings
// E.g.:
//   const STATES = stringsLiteralArray(['ON', 'OFF']);
//   type States = typeof STATES[number]
//
// NOTE: This is obsoleted by Typescript 3.4
export function stringLiteralArray<T extends string>(values: T[]) {
  return values;
}

// Javascript implementation of Java's String.hashCode() method
// https://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/
export function hashCode(str: string): number {
  let hash = 0;

  if (str.length === 0) {
    return hash;
  }

  /* eslint-disable no-bitwise */
  for (let i = 0; i < str.length; i++) {
    let chr = str.charCodeAt(i);
    // tslint:disable-next-line:no-bitwise
    hash = (hash << 5) - hash + chr;
    // tslint:disable-next-line:no-bitwise
    hash |= 0; // Convert to 32bit integer
  }
  /* eslint-enable no-bitwise */

  return hash;
}

export function toggleElem<T>(array: T[], elem: T) {
  const copy = [...array];
  const index = array.findIndex(e => e === elem);
  if (index === -1) copy.push(elem);
  else copy.splice(index, 1);
  return copy;
}

// Helpful utility function for comparing an object ID and a string
// in a scenario where it is unclear of their type or just as a safety
// precaution in case of runtime shenanigans.
export function safeIdCompare(
  param1?: ObjectId | string | null,
  param2?: ObjectId | string | null
) {
  if (param1 && param2) {
    // if both exist and param1 has an equals function
    // (it is an ObjectId or ObjectID)
    if (typeof param1 !== 'string' && param1.equals) {
      return param1.equals(param2);
    }

    // if both exist and param2 has an equals function
    // (it is an ObjectId or ObjectID)
    if (typeof param2 !== 'string' && param2.equals) {
      return param2.equals(param1);
    }
  }

  // if either does not exist, or both are strings
  return param1 === param2;
}

export function forceIdToString(param1: ObjectId | string) {
  if (typeof param1 !== 'string') {
    if (param1.toHexString) {
      return param1.toHexString();
    } else {
      throw new Error(`Invalid ObjectId: ${param1}`);
    }
  }

  return `${param1}`;
}

// https://decipher.dev/30-seconds-of-typescript/docs/debounce/
// tslint:disable-next-line:ban-types
export function debounce(fn: Function, ms = 300) {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
}

export function hasOwnProperty<X extends {}, Y extends PropertyKey>(
  obj: X,
  prop: Y
): obj is X & Record<Y, unknown> {
  return obj.hasOwnProperty(prop);
}

const colorPalette = [
  '#DA7979',
  '#3FA6E6',
  '#65CBF4',
  '#CCB159',
  '#A08B6B',
  '#00ABC0',
  '#77C3BE',
  '#ADADAD',
  '#B6C973',
  '#5D96B7',
  '#EB81BD',
  '#BE9BF2',
  '#9585F8',
  '#E79673',
  '#7CC783',
];

let lastColorCache: string | undefined;

export function hashStringToColor(str: string, noDupes?: boolean) {
  let lastColor = colorPalette[Math.abs(hashCode(str)) % colorPalette.length];
  if (noDupes && lastColorCache === lastColor) {
    lastColor = colorPalette[Math.abs(hashCode(str) + 1) % colorPalette.length];
  }
  lastColorCache = lastColor;
  return lastColor;
}

export function findInitials(str: string | string[], amt: number) {
  let workingStr = typeof str === 'string' ? str : str.join(' ');

  return workingStr
    .split(' ')
    .filter(notUndefined)
    .filter(s => s.length > 0)
    .map(w => w[0].toUpperCase())
    .filter((_, i) => i < amt)
    .join('');
}

export function xor(a: boolean, b: boolean) {
  return (a || b) && !(a && b);
}

// GOTCHA: does not work with recursive objects!!
export function unsafeDeepCopy<T extends {}>(obj: T): T {
  return JSON.parse(JSON.stringify(obj));
}

export function clamp(num: number, min: number, max: number) {
  return Math.min(Math.max(num, min), max);
}

export function limitArray<T>(items: T[], length: number) {
  if (items.length > length) {
    return items.slice(0, length);
  } else {
    return items;
  }
}

export function makeFullName(firstName: string, lastName: string) {
  return [firstName, lastName].filter(f => !!f).join(' ');
}

export function captureBetweenDollars(text: string, includeContext?: boolean) {
  const regex = /\$([A-Z_]+?)(?:\|([^$]+)?)?\$/g;
  return [...text.matchAll(regex)].filter(m => includeContext || !m[1].startsWith('GO_'));
}

const fakeVersion = uuid();

export function generateFakeVersion() {
  return fakeVersion;
}

export function flatList<T>(...args: Array<T[] | undefined>) {
  return args.map(a => a || []).flat();
}

export function cssFriendly(str: string) {
  return str.replace(/ /g, '_');
}
